import styled from "@emotion/styled";

const CollectionTitle = styled.h2`
  body & {
    color: var(--color-charcoal-60);
    font-family: var(--font-family);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 1.05rem */
    letter-spacing: 0.03rem;
    text-transform: uppercase;
    margin-bottom: 0.6rem;

    &.m-bottom-neg {
      margin-bottom: -0.8rem;
    }

    &.m-top {
      margin-top: 1.6rem;
    }

    + nine-info-section {
      margin-top: 0;
    }
  }
`;

export default CollectionTitle;
